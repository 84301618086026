// While many of these methods can be installed via NPM (e.g. lodash),
// this adds external dependencies and increases the JS bundle size which
// is bad for performance. Better to maintain our own simple utility methods.

import Tracker from '@/util/tracker.js'

// deepGet({ a: { b: 'c' } }, ['a', 'b']) === 'c'
export function deepGet(object, keys) {
  let cur = object
  for (const key of keys) {
    cur = cur[key]
  }
  return cur
}

// deepSlice({ a: { b: 'c', other: 'keys' }, are: 'ignored' }, ['a', 'b']) === { a: { b: 'c' } }
export function deepSlice(object, keys) {
  const [key, ...rest] = keys
  return { [key]: rest.length > 0 ? deepSlice(object[key], rest) : object[key] }
}

// deepSet({ a: { b: 'c' } }, ['a', 'b'], 'C!') === { a: { b: 'C!' } }
export function deepSet(obj, keys, value) {
  const [key, ...rest] = keys
  return {
    ...obj,
    [key]: rest.length > 0 ? deepSet(obj[key] ?? {}, rest, value) : value,
  }
}

export function deepMerge(...args) {
  const [a, b, ...rest] = args
  if (rest.length > 0) {
    return deepMerge(deepMerge(a, b), ...rest)
  }

  if (a == null) {
    return b
  }
  if (b == null) {
    return a
  }

  if (Array.isArray(b) || typeof b !== 'object' || typeof a !== 'object')
    return b
  return [...Object.keys(a), ...Object.keys(b)].reduce(
    (out, key) => ({ ...out, [key]: deepMerge(a[key], b[key]) }),
    {},
  )
}

export function deepEqual(a, b) {
  if (Array.isArray(a) && Array.isArray(b)) {
    if (a.length !== b.length) return false
    for (const index in a) {
      if (!deepEqual(a[index], b[index])) return false
    }
    return true
  } else if (typeof a !== 'object' || a == null) {
    return a === b
  }
  if (typeof b !== 'object' || b == null) {
    return false
  }
  const aKeys = Object.keys(a)
  const bKeys = Object.keys(b)
  if (aKeys.length !== bKeys.length) return false
  for (const key of aKeys) {
    if (!deepEqual(a[key], b[key])) return false
  }
  return true
}

// removes null and undefined values from arrays and objects, removing
// keys that resolve to empty.
export function deepCompact(object) {
  if (object === null || object === undefined) {
    return null
  }
  if (Array.isArray(object) || typeof object !== 'object') {
    return object
  }
  const compactedObject = {}
  for (const key of Object.keys(object)) {
    const val = deepCompact(object[key])
    if (val !== null && val !== undefined) {
      compactedObject[key] = val
    }
  }
  if (Object.keys(compactedObject).length === 0) return null
  return compactedObject
}

export function deepCopy(object) {
  if (typeof object !== 'object' || object === null) {
    return object
  }
  if (Array.isArray(object)) {
    return object.map((v) => deepCopy(v))
  }
  return Object.entries(object).reduce(
    (acc, [key, val]) => ({ ...acc, [key]: deepCopy(val) }),
    {},
  )
}

export function isBlank(value) {
  return (value ?? '').length === 0
}

export function hasValue(value) {
  return value !== null && value !== undefined && value !== ''
}

export function isSuperset(set, subset) {
  for (const elem of subset) {
    if (!set.has(elem)) {
      return false
    }
  }
  return true
}

export function swap(a, b) {
  return [b, a]
}

export function min(...vals) {
  return extent(vals, (a, b) => a < b)
}

export function max(...vals) {
  return extent(vals, (a, b) => a > b)
}

export function extent(vals, less) {
  let m = vals[0]
  for (let i = 1; i < vals.length; i++) {
    if (less(vals[i], m)) {
      m = vals[i]
    }
  }
  return m
}

export function leftPad(str, padding, length) {
  if (str.length >= length) {
    return str
  }
  return leftPad(padding + str, padding, length)
}

// select the element from elements that has the largest
// value returned by fn.
export function maxBy(elements, fn, sortMode = 'numeric') {
  const array = Array.from(elements)
  if (array.length === 0) return undefined

  const sortFn = sortMode === 'numeric' ? (a, b) => a - b : undefined
  const map = new Map(array.map((element) => [fn(element), element]))
  const maxKey = Array.from(map.keys()).sort(sortFn)[map.size - 1]
  return map.get(maxKey)
}

export async function wait(t) {
  return await new Promise((resolve) => setTimeout(resolve, t))
}

export function encodeURIParams(params) {
  return Object.entries(params)
    .reduce(
      (acc, [k, v]) => [
        ...acc,
        `${encodeURIComponent(k)}=${encodeURIComponent(v)}`,
      ],
      [],
    )
    .join('&')
}

// trackImport can be used to track caught failed dyanimc imports. The
// intended usage is
//
//    await import('some/path').catch(trackImport('some/path'))
//
// This is implemented like this because a string literal, not any
// string value, needs to be passed directly to import() or else it
// won't get detected correctly by the bundler.
export const trackImport = (path) => async (err) => {
  await Tracker.track('dynamic import failed', {
    path,
    err: err.toString(),
  })
}
